import styled from 'styled-components'



export const AsicsbackTotem = styled.div`
  background-color: #c4ddf2;
  min-height: 102vh;
  width: 100%;
  padding-bottom: 30px;
  margin-top: -80px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  div{
    display: flex;
    justify-content: center;
    padding-top: 200px;
    iframe{
        border: none;
        border-radius: 10px;
        background-color: white;
    }
  }
  @media (max-width: 767px){
    padding-right: 0;
    div{
      padding: 200px 20px 0;
    }
  }
  @media (max-width: 450px){
    div{
      padding: 130px 20px 0;
      iframe{
        width: 330px;
        height: 80vh;
      }
    }
  }


`