import React, { useEffect } from 'react'
import * as S from '../asicsback/styles'
import { graphql } from 'gatsby'
import Layout from '../../../components/totem/layout'
import PropTypes from 'prop-types'
import { ditoTrack } from '../../../helpers/dito'
import BtnVoltar from '../../../components/totem/Voltar'


const AsicsbackTotem = ({ pageContext: { loja } }) => {

  useEffect(() => {
    ditoTrack('acessou-asics-back')
  }, [])


  return (
    <Layout loja={loja}>
      <S.AsicsbackTotem>
        <div>
          <iframe
            src="https://back.asics.com.br/"
            width={650}
            height={810}
            title="Asics - Back"
            loading="eager"
          ></iframe>
        </div>
      </S.AsicsbackTotem>
      <BtnVoltar></BtnVoltar>
    </Layout>
  )
}

AsicsbackTotem.propTypes = {
  pageContext: PropTypes.shape({
    loja: PropTypes.shape({
      nome: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default AsicsbackTotem

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
